import { useTranslations } from "next-intl";

import { useCart } from "~/hooks/use-cart";
import cartUtils from "~/utils/cart-utils";
import intlUtils from "~/utils/intl-utils";

import PromoLabel from "../common/promo-label";
import styles from "./discount-product-tile.module.scss";

type Props = {
  discount: EVA.Core.OrderLineDiscount;
  line: EVA.Core.OrderLineDto;
};

export default function DiscountProductTile({ discount, line }: Props) {
  const t = useTranslations();
  const priceFormatter = intlUtils.usePriceFormatter();
  const cart = useCart();
  const currency = line.CurrencyID;

  const promoLabelLocalized = cart?.ShoppingCart.Lines.find(
    (line) => line.DiscountID == discount.DiscountID
  )?.Description;

  return (
    <div className={styles.discountLine}>
      {cartUtils.isProductFree(line) ? (
        <div className={styles.nameWithLabel}>
          {/* PromotionLabel should be the localized one TODO 
          ask kiko to create a CR on NewBlack for this as suggested by Stan https://support.newblack.io/support/tickets/12847 */}
          <p className={styles.name}>{promoLabelLocalized || discount.Description}</p>
          <PromoLabel iconName="gift" text={t("generic.free")} />
        </div>
      ) : (
        <>
          <p className={styles.name}>{promoLabelLocalized || discount.Description}</p>
          <p className={styles.displayPrice}>{priceFormatter.currency(discount.DiscountAmount, { currency })}</p>
        </>
      )}
    </div>
  );
}
