import Constants from "./constants";

const cartUtils = {
  calculateCartTotals(cart?: EVA.Core.ShoppingCartResponse, withTax?: boolean) {
    if (!cart) {
      return {
        total: 0,
        subtotal: 0,
        discountLines: [],
        giftWrapping: 0,
        shippingAmount: 0,
        shippingMethod: null,
        tax: 0,
        estimated: true,
      };
    }

    // cart.ShoppingCart.IsPaid should not be considered anymore since SDK update
    if (cart.ShoppingCart.Lines.length === 0) {
      return {
        total: 0,
        subtotal: 0,
        discountLines: [],
        giftWrapping: 0,
        shippingAmount: 0,
        shippingMethod: null,
        tax: 0,
        estimated: true,
      };
    }

    const discounts = cart.Amounts.Discounts.PerDiscount;
    const discountLines = discounts.map((discount) => {
      const findPrefiguredDiscounts = cart.AdditionalOrderData?.PrefiguredDiscounts?.find(
        (prefiguredDiscount) => prefiguredDiscount.DiscountID === discount.DiscountID
      );

      return {
        discount: discount,
        description: findPrefiguredDiscounts?.MarketingDescription ?? discount.Discount.Description,
      };
    });

    const subtotal = cart.Amounts.Types.NormalProduct
      ? withTax
        ? cart.Amounts.Types.NormalProduct.Amount
        : cart.Amounts.Types.NormalProduct.InTax
      : 0;
    const total = cart.Amounts.Total.InTax;
    const taxTotal = withTax ? cart.Amounts.Total.Tax : 0;
    const giftWrapping = cart.Amounts.Types.GiftWrappingCosts
      ? withTax
        ? cart.Amounts.Types.GiftWrappingCosts.Amount
        : cart.Amounts.Types.GiftWrappingCosts.InTax
      : 0;

    const shippingMethod =
      cart.ShoppingCart.Lines.find((line) => line.ShippingMethod && !line.IsCancelled)?.ShippingMethod!.Name || null;
    const hasShippingMethod = cart.ShoppingCart.Lines.some((line) => line.ShippingMethodID);
    const shippingCostsLine = cart.ShoppingCart.Lines.find((line) => line.Type === 5);

    if (shippingCostsLine !== undefined) {
      // The user has already selected a shipping method in the checkout page
      const shippingAmount = withTax ? shippingCostsLine.NetTotalAmount : shippingCostsLine.NetTotalAmountInTax;

      return {
        total,
        subtotal,
        discountLines: discountLines,
        giftWrapping,
        shippingAmount,
        shippingMethod,
        tax: taxTotal,
        estimated: false,
      };
    } else if (hasShippingMethod) {
      // The user has selected a shipping method, however the line disappears when the free shipping is reached
      const shippingAmount = 0;

      return {
        total,
        subtotal,
        discountLines: discountLines,
        giftWrapping,
        shippingAmount,
        shippingMethod,
        tax: taxTotal,
        estimated: false,
      };
    } else {
      // The user has not selected a shipping method yet
      const methods = cart.AdditionalOrderData?.AvailableShippingMethods ?? [];
      const costs = methods.map((method) => method.Costs.Amount).filter((cost) => cost > 0);

      const virtualProducts = cartUtils.getVirtualProducts(cart);

      // virtual products only cart (no shipping cost in total)
      if (!cart.ShoppingCart.HasDelivery || virtualProducts.length === cart.ShoppingCart.TotalItems) {
        const shippingAmount = 0;

        return {
          total,
          subtotal,
          discountLines: discountLines,
          giftWrapping,
          shippingAmount,
          shippingMethod,
          tax: taxTotal,
          estimated: false,
        };
      } else {
        // for mixed cart with no shipping selected yet, add the minimum shipping cost
        const shippingAmount = costs.length > 0 ? Math.min(...costs) : 0;

        return {
          total: total + shippingAmount,
          subtotal,
          discountLines: discountLines,
          giftWrapping,
          shippingAmount,
          shippingMethod,
          tax: taxTotal,
          estimated: true,
        };
      }
    }
  },
  calculateFreeShippingThreshold(cart: EVA.Core.ShoppingCartResponse | undefined) {
    if (!cart) {
      return undefined;
    }

    const shippingMethods = cart.AdditionalOrderData?.AvailableShippingMethods ?? [];

    if (shippingMethods.length === 0) {
      return undefined;
    }

    const thresholds = shippingMethods.map((sm) => sm.Costs.Ranges.find((r) => r.Costs === 0)!).filter(Boolean);

    if (thresholds.length === 0) {
      return undefined;
    }

    return thresholds.sort((a, b) => a.MinAmount - b.MinAmount)[0].MinAmount;
  },
  // TODO Unfortunately there is no field that tells if the product is free, for the moment FE will check if:
  // line.CustomOrderLineType exists
  // line.CustomOrderLineType === "DISCOUNTPRODUCT"
  // the subtraction between price of the product and the discount is equal to 0
  // Related New Black ticket KIKO-191
  // Related discussion https://openmindonline.slack.com/archives/C04ND8S25DZ/p1708010414600489
  isProductFree(line: EVA.Core.OrderLineDto | undefined): boolean {
    return (line !== undefined &&
      line.CustomOrderLineType === "DISCOUNTPRODUCT" &&
      line?.TotalAmountInTax !== undefined &&
      line.Discounts?.[0]?.DiscountAmount !== undefined &&
      line.TotalAmountInTax + line.Discounts[0].DiscountAmount === 0) as boolean;
  },
  findShippingMethodWithLowestFreeShipping(cart: EVA.Core.ShoppingCartResponse | undefined) {
    if (!cart) {
      return undefined;
    }

    const shippingMethods = cart.AdditionalOrderData?.AvailableShippingMethods ?? [];

    if (shippingMethods.length === 0) {
      return undefined;
    }

    const methodsWithFreeShipping = shippingMethods.filter((sm) => sm.Costs.Ranges.some((r) => r.Costs !== undefined));

    const methodWithLowestFreeShipping = methodsWithFreeShipping.sort((a, b) => {
      const aMinAmount = a.Costs.Ranges.find((r) => r.Costs === 0)?.MinAmount!;
      const bMinAmount = b.Costs.Ranges.find((r) => r.Costs === 0)?.MinAmount!;

      return aMinAmount - bMinAmount;
    })[0];

    return {
      method: methodWithLowestFreeShipping,
      amount: methodWithLowestFreeShipping.Costs.Ranges.find((r) => r.Costs === 0)?.MinAmount!,
    };
  },
  getVirtualProducts(cart: EVA.Core.ShoppingCartResponse | undefined) {
    return (
      cart?.ShoppingCart.Lines.filter((line) =>
        line.Product?.Properties.product_types.includes(Constants.VIRTUAL_PRODUCT)
      ) || []
    );
  },
  hasOnlyVirtualProducts(cart: EVA.Core.ShoppingCartResponse): boolean {
    return cart && this.getVirtualProducts(cart).length === cart.ShoppingCart.Lines.length;
  },
};

export default cartUtils;
